import React from "react";

import { Container, Title, Body } from "./legal.styles";

const PrivacyPolicyPersonal = () => (
    <Container>
        <Title>Privacy Policy</Title>
        <Body>
            <Title>Ümit Semih Cihan</Title>
            <Body>
                <div>
                    is committed to protecting the privacy of our users. This
                    Privacy Policy outlines the types of information we collect
                    from users of our game, how we use that information, and the
                    measures we take to safeguard it.
                </div>
            </Body>
        </Body>

        <Title>1. Information We Collect</Title>
        <Body>
            <div>
                <Title>Information You Provide:</Title>
                <br />
                <Body>
                    We may collect information that you voluntarily provide to
                    us when using our game, such as your username, email
                    address, or other contact information you choose to share.
                </Body>
            </div>
            <div>
                <Title>Automatically Collected Information:</Title>
                <br />
                <Body>
                    We may automatically collect certain information about your
                    device, including your device type, operating system, unique
                    device identifiers, IP address, and mobile network
                    information. This information is collected to improve the
                    functionality of our game and provide a better user
                    experience.
                </Body>
            </div>
        </Body>

        <Title>2. How We Use Your Information</Title>
        <Body>
            <div>
                <Title>To Improve Our Game:</Title>
                <br />
                <Body>
                    We use the information we collect to analyze user behavior
                    and preferences, troubleshoot technical issues, and improve
                    the overall performance and functionality of our game.
                </Body>
            </div>
            <div>
                <Title>To Communicate With You:</Title>
                <br />
                <Body>
                    We may use your contact information to respond to your
                    inquiries, provide customer support, and send you important
                    updates or notifications related to our game.
                </Body>
            </div>
        </Body>

        <Title>3. Information Sharing</Title>
        <Body>
            <div>
                <Title>Third-Party Service Providers:</Title>
                <br />
                <Body>
                    We may share certain information with third-party service
                    providers that assist us in operating our game, such as
                    analytics providers, push notification services, or payment
                    processors. These service providers are contractually
                    obligated to use your information solely for the purpose of
                    providing their services to us and are prohibited from using
                    it for any other purpose.
                </Body>
            </div>
            <div>
                <Title>Legal Requirements:</Title>
                <br />
                <Body>
                    We may disclose your information if required to do so by law
                    or in response to a valid legal request, such as a court
                    order or subpoena.
                </Body>
            </div>
        </Body>

        <Title>4. Data Security</Title>
        <Body>
            <div>
                <Body>
                    We take reasonable measures to protect the information we
                    collect from unauthorized access, disclosure, alteration, or
                    destruction. However, please be aware that no method of
                    transmission over the internet or electronic storage is 100%
                    secure, and we cannot guarantee the absolute security of
                    your information.
                </Body>
            </div>
        </Body>

        <Title>5. Children's Privacy</Title>
        <Body>
            <div>
                <Body>
                    Our product is not intended for children under the age of
                    13, and we do not knowingly collect personal information
                    from children under this age. If you are a parent or
                    guardian and believe that your child has provided us with
                    personal information, please contact us immediately, and we
                    will take steps to remove such information from our records.
                </Body>
            </div>
        </Body>

        <Title>6. Changes to This Privacy Policy</Title>
        <Body>
            <div>
                <Body>
                    We reserve the right to update or modify this Privacy Policy
                    at any time without prior notice. Any changes will be
                    effective immediately upon posting the updated Privacy
                    Policy on our game. We encourage you to review this Privacy
                    Policy periodically for any changes.
                </Body>
            </div>
        </Body>

        <Title>7. Contact Us</Title>
        <Body>
            <div>
                <Body>
                    If you have any questions or concerns about our Privacy
                    Policy or the practices described herein, please contact us
                    at contact@lagotgames.com.
                </Body>
            </div>
        </Body>

        <Body>
            <div>
                <Body>
                    By using our game, you consent to the collection and use of
                    your information as outlined in this Privacy Policy.
                </Body>
            </div>
        </Body>
    </Container>
);

export default PrivacyPolicyPersonal;
